.App {
  text-align: left;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

table, th, td, tr {
  border: 1px solid rgb(1, 1, 1);
  border-width: 1px;
  /* border-collapse: collapse; */
}
table {
  padding: 10px;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  padding: 10px;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: baseline; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
